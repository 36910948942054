import { useReducer, useEffect, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import {
  initialState,
  reducer,
  fetchWalletData,
  getWalletDetails,
  storeVoucherCode,
  fetchWalletDataPDP,
} from './handlers'
import { EventTrackingContext } from '../../EventTrackingProvider/EventTrackingProvider'
import {
  eventsDataset,
  TRACKER_CONSTANTS,
  GTM_ENV_VAR as GEV,
} from '../utils/vouchersTracker'
import {
  AMP_EVENT_NAME as AEN,
  CTA_LOCATION_NAME,
} from '../../../lib/amplitude'
import { getConfigFor } from '../../../utils/configService'
import { SPLIT_FEATURES } from '../../../utils/constants'

const useVoucherTabHook = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const router = useRouter()
  const code =
    router.query?.code || router.query?.savePromoCode || router.query?.promoCode
  const filterFromUrl = router.query?.filter
  const vouchersActiveTab = router.query?.vouchersActiveTab
  const { track } = useContext(EventTrackingContext)

  const { configuration } = getConfigFor(SPLIT_FEATURES.VOUCHER_WALLET_SORTING)

  const { variantKey } = configuration

  useEffect(() => {
    track(eventsDataset[TRACKER_CONSTANTS.LANDED_VOUCHER_WALLET])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      value: vouchersActiveTab === 'past' ? 1 : 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const storeVoucherCodeDetails = useCallback(
    async isVoucher => {
      storeVoucherCode(state.activeFilter, isVoucher, { code, dispatch })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [code]
  )

  useEffect(() => {
    if (code) {
      const isVoucher = router.query?.savePromoCode || router.query?.promoCode
      storeVoucherCodeDetails(isVoucher)
    }

    if (!code && !props?.productId) {
      fetchWalletData(
        vouchersActiveTab || 'active',
        state.activeFilter,
        {
          dispatch,
        },
        variantKey
      )
    }

    if (!code && props?.productId && props?.storeId) {
      fetchWalletDataPDP(
        'active',
        props.storeId,
        {
          dispatch,
        },
        props.productId,
        variantKey,
        props?.categoryIds
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    const currentFilter = Object.values(state.activeFilter)[0]

    // Below if condition is triggered upon page load only if filter value is not All
    if (filterFromUrl !== currentFilter) {
      let filterIndex = -1
      if ('newFilters' in state.activeData) {
        filterIndex = state.activeData.newFilters.findIndex(
          x => x.name === filterFromUrl
        )
      }

      if (filterIndex > -1) {
        const mainFilterName = state.activeData.newFilters[filterIndex].name
        mapDispatch.handleFilter(
          filterIndex, // index 0, 1, 2
          mainFilterName, // All, Delivery, In-store, Kopitiam, Pay via app, pre-order, Scan & go
          vouchersActiveTab || 'active', // 0=use now tab or 1=past tab
          filterFromUrl
        )
      }
    }
  }, [filterFromUrl, state.activeData])

  useEffect(() => {
    const timer = setTimeout(
      () =>
        dispatch({
          type: 'HIDE_TOAST',
        }),
      4000
    )
    return () => clearTimeout(timer)
  }, [])

  const mapDispatch = {
    parentDispatch: dispatch,
    handleChange: async eventIndex => {
      dispatch({ type: 'SET_ACTIVE_TAB', value: eventIndex })
      if (state.pastData.length === 0) {
        dispatch({ type: 'LOADING' })
      }
      if (eventIndex === 1) {
        fetchWalletData('past', state.activeFilter, { dispatch })
        const event = {
          [GEV.AMPLITUDE_EVENT_NAME]: AEN.HISTORY_VOUCHERS_CLICKED,
          [GEV.ASSET_TYPE]: 'REWARDS',
          [GEV.AMPLITUDE_CTA_LOCATION]: {
            pageName: CTA_LOCATION_NAME.VOUCHER_WALLET,
          },
          [GEV.AMPLITUDE_CTA_SUBLOCATION]: null,
        }
        track(event)
      } else {
        fetchWalletData('active', state.activeFilter, { dispatch }, variantKey)
      }
      const activeTab = eventIndex === 1 ? 'past' : 'active'
      router.query.vouchersActiveTab = activeTab
      router.push(router, undefined, { shallow: true })
    },
    handleViewClick: async (voucherCode, type) => {
      try {
        const results = await getWalletDetails(voucherCode)
        dispatch({ type: 'SHOW_VOUCHER_DETAILS', value: results })
        if (type === 'voucher') {
          track(
            eventsDataset[TRACKER_CONSTANTS.ON_VOUCHER_WALLET_EVOUCHER_DETAIL]
          )
        } else {
          track(
            eventsDataset[TRACKER_CONSTANTS.ON_VOUCHER_WALLET_PROMOCODE_DETAIL]
          )
        }
      } catch (e) {
        dispatch({ type: 'SET_ERROR', value: e })
      }
    },
    handleFilter: (id, tag, tab, subFilter = '') => {
      const all = 'All (including Cashier/Self-checkout)'
      const tagState = subFilter === all ? 'In-store' : subFilter || tag

      // filter param should be either All or Delivery or In-store (no subFilters)
      router.query.filter = tag
      router.push(router, undefined, { shallow: true })
      const filteredTag = Object.fromEntries([[id, tagState]])
      let filteredData = []
      if (tagState !== 'All') {
        let walletData = state.activeData
        if (tab === 'past') {
          walletData = state.pastData
        }
        filteredData = walletData.list?.filter(voucher =>
          voucher.tags.find(value => value === tagState)
        )
        if (tagState === 'Delivery') {
          filteredData = walletData.list?.filter(voucher =>
            voucher.tags.find(value => value === 'Online')
          )
        }
        if (subFilter === all || tagState === 'In-store') {
          filteredData = walletData.list?.filter(voucher =>
            voucher.tags.find(value =>
              [tag, 'Pre-order', 'Pay via app'].includes(value)
            )
          )
        }
      }
      dispatch({
        type: 'SET_ACTIVE_FILTER_VALUE',
        filteredTag,
        filteredData,
      })
    },
    onHandleClose: () => dispatch({ type: 'HIDE_VOUCHER_DETAILS' }),
    track,
    eventsDataset,
    TRACKER_CONSTANTS,
    GEV,
  }

  return [state, mapDispatch]
}

export default useVoucherTabHook
