import React from 'react'
import SC from './elements'
import VoucherIcon from '../Checkout/VoucherIcon'
import { GTM_ENV_VAR as GEV } from '../../../lib/eventTrackerFunction'
import { TRACKER_CONSTANTS } from '../../Vouchers/utils/vouchersTracker'
import { AMP_EVENT_NAME, CTA_LOCATION_NAME } from '../../../lib/amplitude'
import DEFAULT_CARD_IMG from '../../../static/icons/default_card.svg'

const getImageSrc = item => {
  if (item.type === 'voucher') {
    return item?.imgUrls?.logo || DEFAULT_CARD_IMG
  }
  return item?.imgUrls?.logo || item?.iconType
}

const getFilterType = filter => {
  switch (filter) {
    case 'Delivery':
      return 'Online'
    case 'In-Store':
      return 'Scan & Go'
    default:
      return filter
  }
}

const UseNow = ({
  item,
  isVoucher,
  dispatch,
  track,
  i,
  isAllfilterValue,
  imgLoading,
  description,
  expiresAt,
}) => {
  const url = new URL(window.location.href)
  const filter = url.searchParams.get('filter') || 'All'
  const tabActiveFilter = getFilterType(filter)
  const uniqueTabs = [...new Set(item.tags)]
  const groupTagsData = uniqueTabs.filter(tag =>
    ['All', 'Online'].includes(tabActiveFilter)
      ? item.tags
      : tag === tabActiveFilter
  )
  const inStore = ['Scan & Go', 'Pay via app', 'Pre-order', 'in-store']
  const isInstoreFilter = inStore.some(
    tag => tag.toLowerCase() === tabActiveFilter.toLowerCase()
  )
  const filteredWalletData = uniqueTabs?.filter(r => !['Online'].includes(r))
  const isScanAndGo =
    tabActiveFilter === 'In-store' ? 'Scan & Go' : tabActiveFilter
  const inStoreTags = [...new Set(filteredWalletData)].sort((a, b) =>
    isScanAndGo.localeCompare(b)
  )

  const filteredGroupData = groupTagsData
    .filter(u =>
      ['Online', 'In-store', 'Scan & Go', 'Pay via app', 'Pre-order'].includes(
        u
      )
    )
    .map(filteredTag =>
      inStore.includes(filteredTag) ? 'In-Store' : filteredTag
    )

  const tags = isInstoreFilter ? inStoreTags : filteredGroupData

  return (
    <SC.ContainerWrapper
      item
      isVoucher={isVoucher}
      id="card-container"
      onClick={() => {
        dispatch.handleViewClick(item.code, item.type)

        isVoucher &&
          track({
            [GEV.EVENT_CAT]: 'grocery-home-voucher',
            [GEV.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
            [GEV.EVENT_ACTION]: 'PromotionImpressions',
            [GEV.EVENT_LABEL]: `Content-${item.GSTSKUDOLLAROFF} ${i}`,
            [GEV.AMPLITUDE_EVENT_NAME]: 'hm_push_event',
            [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.VOUCHER_SWIMLANE_SWIPED,
            [GEV.AMPLITUDE_EVENT_TYPE]: AMP_EVENT_NAME.VOUCHER_EVENT_PROPERTY,
            [GEV.AMPLITUDE_CTA_LOCATION]: CTA_LOCATION_NAME.HOME,
            [GEV.AMPLITUDE_VOUCHER_POSITION]: i,
          })
      }}
    >
      <SC.StyledBox
        data-new={Boolean(item.label) || isVoucher}
        data-count={i > 0 && !isVoucher}
        isVoucher={isVoucher}
        isAllfilterValue={isAllfilterValue}
        data-popup={false}
        role="tab"
        id={'voucher-box' + i}
      >
        <div className="top-icon" />
        <div className="bottom-icon" />
        <div className="qrcode-box">
          {item.label && (
            <SC.NewBadge isVoucher={isVoucher} variant="subhead2">
              {item.label}
            </SC.NewBadge>
          )}
          <SC.QrIconBox
            isVoucher={isVoucher}
            className="qrcode"
            data-new={Boolean(item.label) && !isVoucher}
          >
            {imgLoading && (
              <SC.StyledImageWrapper>
                <SC.NewSkeleton width="3.75" height="3.75" />
              </SC.StyledImageWrapper>
            )}
            <VoucherIcon
              iconType={getImageSrc(item)}
              type={item?.type}
              className={imgLoading ? 'voucher-icon' : ''}
              onLoad={() => dispatch.parentDispatch({ type: 'IMAGE_LOADED' })}
            />
          </SC.QrIconBox>
        </div>
        <div className="text-info">
          <SC.DashedBox>
            <SC.InfoBox isVoucher={isVoucher}>
              <SC.Box isVoucher={isVoucher}>
                {item.tags.length > 0 &&
                  !isVoucher &&
                  tags.map((tag, j) => {
                    const isOnline = tag === 'Online'
                    return (
                      j < 2 && (
                        <SC.StyledTag variant="subhead2" key={j}>
                          {isOnline ? 'Delivery' : tag}
                        </SC.StyledTag>
                      )
                    )
                  })}
                <SC.StyledTitle
                  isVoucher={isVoucher}
                  variant="subhead1"
                  isEmpty={item?.tags?.length === 0}
                  voucherTab
                >
                  {item.detail.title || item?.code}
                </SC.StyledTitle>
                <SC.StyledSubTitle
                  isVoucher={isVoucher}
                  variant="subhead1"
                  voucherTab
                >
                  {item?.detail?.subTitle ? item.detail.subTitle : description}
                </SC.StyledSubTitle>
                <SC.Validity
                  variant="body4"
                  expired={Boolean(expiresAt.match(/[~]/))}
                >
                  {expiresAt.replace(/[~]/g, '')}
                </SC.Validity>
              </SC.Box>
              <SC.StyledLink
                size="s"
                target="_blank"
                rel="nofollow"
                data-testid={item.code}
              >
                <SC.SvgArrowRight size="m" color="#999999" />
              </SC.StyledLink>
            </SC.InfoBox>
          </SC.DashedBox>
        </div>
      </SC.StyledBox>
    </SC.ContainerWrapper>
  )
}

export default UseNow
