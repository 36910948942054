import React, { Fragment } from 'react'
import Link from '@ntuctech/devex-tangram/Link'
import Barcode from 'react-barcode'
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained'
import SvgAlert from '@ntuctech/devex-tangram/Icon/SvgAlert'
import lineBreaker from '../utils/lineBreaker'
import QrCodeDetailsPopUp from './QrCodeDetailsPopUp'
import TabSection from './TabSection'
import TransactionHistorySection from './TransactionHistorySection'
import SC from './elements'
import VC from '../Checkout/elements'
import VoucherIcon from '../Checkout/VoucherIcon'
import VoucherEligibleItems from './VoucherEligibleItems'
import { GTM_ENV_VAR as GEV } from '../../../lib/eventTrackerFunction'

const VoucherDetailsPopUp = ({
  voucherDetails,
  dispatch,
  pastTab,
  showQrCode,
  showHowToUse,
  page,
  imgLoading,
  isVoucher,
  isSideSheet,
}) => {
  const expiresAt = voucherDetails?.expiresAt || ''

  let description = voucherDetails.description
  if (voucherDetails.type === 'voucher') {
    description = `${voucherDetails.balance} (${voucherDetails.description})`
  }
  const isTypeVoucher = voucherDetails.type === 'voucher'
  const info = isTypeVoucher && voucherDetails.info

  return (
    <>
      {info && !pastTab && (
        <VC.StyledCodeBox info={info}>
          <VC.SelectedVoucherCount isVoucher={isVoucher}>
            <SvgAlert size="s" color="#1454B8" />
            {info}
          </VC.SelectedVoucherCount>
        </VC.StyledCodeBox>
      )}
      <VC.StyledBoxContainer>
        <VC.StyledBox
          isVoucher={isVoucher}
          wallet
          key={voucherDetails.id}
          data-popup="true"
        >
          <div className="top-icon" />
          <div className="bottom-icon" />
          <div className="qrcode-box">
            <VC.QrIconBox className="qrcode">
              {imgLoading && (
                <SC.StyledImageWrapper>
                  <SC.NewSkeleton width="3.75" height="3.75" />
                </SC.StyledImageWrapper>
              )}
              <VoucherIcon
                onLoad={() => dispatch.parentDispatch({ type: 'IMAGE_LOADED' })}
                isPast={pastTab}
                className={imgLoading ? 'voucher-icon' : ''}
                iconType={
                  voucherDetails?.imgUrls?.logo || voucherDetails.iconType
                }
              />
            </VC.QrIconBox>
          </div>
          <div className="text-info">
            <VC.DashedBox>
              <VC.InfoBox data-popup="true">
                <SC.BoxWrapper>
                  {isVoucher &&
                    voucherDetails?.tags?.map((tag, j) => (
                      <SC.StyledTag
                        variant="subhead2"
                        key={j}
                        data-past={pastTab}
                      >
                        {tag}
                      </SC.StyledTag>
                    ))}
                  {(voucherDetails?.title || voucherDetails?.detail?.title) && (
                    <SC.StyledTitle
                      isVoucher={isVoucher}
                      data-past={pastTab}
                      variant="subhead1"
                      data-popup="true"
                    >
                      {voucherDetails?.title || voucherDetails?.detail?.title}
                    </SC.StyledTitle>
                  )}
                  <SC.StyledSubTitle
                    isVoucher={isVoucher}
                    data-past={pastTab}
                    data-popup="true"
                    variant="h5"
                    view
                  >
                    {voucherDetails?.subTitle ||
                    voucherDetails?.detail?.subTitle
                      ? voucherDetails?.subTitle ||
                        voucherDetails?.detail?.subTitle
                      : description}
                  </SC.StyledSubTitle>
                  <VC.StyledSubHead2 data-popup="true">
                    {voucherDetails.code}
                  </VC.StyledSubHead2>
                  {voucherDetails.showAs === 'barline' && (
                    <Barcode
                      value={voucherDetails.code}
                      displayValue={false}
                      width={1.6}
                      height={40}
                      marginTop={5}
                      marginLeft={-4}
                      marginBottom={5}
                    />
                  )}
                  <SC.Validity
                    variant="body4"
                    expired={Boolean(expiresAt.match(/[~]/))}
                  >
                    {expiresAt.replace(/[~]/g, '')}
                  </SC.Validity>
                </SC.BoxWrapper>
              </VC.InfoBox>
            </VC.DashedBox>
          </div>
        </VC.StyledBox>
      </VC.StyledBoxContainer>
      {(voucherDetails?.howToUse || voucherDetails?.tncUrl) && (
        <SC.StyledSection>
          <SC.Typography variant="h6">How to use</SC.Typography>
          <SC.Typography
            className="description"
            dangerouslySetInnerHTML={{
              __html: lineBreaker(
                voucherDetails.howToUse.replace(
                  /~~([^~]+)~~/g,
                  '<span class="highlight">$1</span>'
                )
              ),
            }}
          />
          {isTypeVoucher && !isVoucher && (
            <Fragment>
              <TabSection
                whereToRedeem={voucherDetails?.whereToRedeem}
                tnc={voucherDetails?.tnc}
              />
              <TransactionHistorySection
                transactions={voucherDetails.transactions}
              />
            </Fragment>
          )}
          {voucherDetails.tncUrl && (
            <SC.TermsContainer>
              <Link
                size="m"
                href={voucherDetails.tncUrl}
                target="_blank"
                data-testid="voucher-tnc-item-link"
                rel="nofollow"
                color="#1557BF"
                weight="bold"
                onClick={() => {
                  dispatch.track({
                    [GEV.AMPLITUDE_EVENT_NAME]: 'terms and conditions clicked',
                    [GEV.AMPLITUDE_VERTICAL]: 'rewards',
                    [GEV.AMPLITUDE_CALL_TO_ACTION]: 'rewards | voucher details',
                    [GEV.AMPLITUDE_CTA_SUBLOCATION]: null,
                    [GEV.AMPLITUDE_VOUCHER_CODE]: voucherDetails?.code,
                    [GEV.AMPLITUDE_VOUCHER_TYPE]: voucherDetails?.type,
                  })
                }}
              >
                Terms &amp; Conditions
              </Link>
            </SC.TermsContainer>
          )}
        </SC.StyledSection>
      )}
      <VoucherEligibleItems
        isSideSheet={isSideSheet}
        voucherDetails={voucherDetails}
        track={dispatch.track}
        isVoucher={isVoucher}
        wallet
      />
      {!pastTab && isTypeVoucher && page !== 'checkout' && (
        <div style={{ margin: '0rem 2.5rem' }}>
          <ButtonContained
            onClick={() => {
              dispatch.track(
                dispatch.eventsDataset[
                  dispatch.TRACKER_CONSTANTS.ON_VOUCHER_WALLET_USE_NOW_CLICK
                ]
              )
              dispatch.parentDispatch({ type: 'SHOW_QR_CODE' })
            }}
            fullwidth
            size="m"
            className="apply-btn ndsHidden-s-up"
            data-testid="usenow-btn"
            style={{ width: '100%', margin: 'auto' }}
          >
            Use now
          </ButtonContained>
        </div>
      )}
      {isTypeVoucher && showQrCode && (
        <QrCodeDetailsPopUp
          voucherDetails={voucherDetails}
          dispatch={dispatch}
          showHowToUse={showHowToUse}
          imgLoading={imgLoading}
        />
      )}
    </>
  )
}

export default VoucherDetailsPopUp
